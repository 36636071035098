import http from "@/server/proxy";

import { makeAutoObservable } from "mobx";

class Config {
  constructor() {
    makeAutoObservable(this);
  }
  logoimg = "";
  getLogImg() {
    http.get("/user?key=app_config").then((res) => {
      const obj = JSON.parse(res?.data || "{}");
      this.logoimg = obj.admin_web.logo;
    });
  }
}
const rolestore = new Config();
export default rolestore;
