/* eslint-disable no-prototype-builtins */
import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../uitls";
// import http from "./proxy"
// const axios = http
export async function getorderlist(data: any): Promise<any> {
    console.log(data);
    const stringbnum = (e: any) => {
        const arr = e || ""
        return `${arr}`
    }

    const query = `
        {order_list( order_num: "${stringbnum(data.name)}", team_id: ${data.title || 0}, operator_id: ${data.xsid || 0}, invoice_status: ${data.kpstatue || 0}, pay_type: ${data.fs_type || 0}, audit_status: ${data.shstatus || 0}, start_time: ${data.state_trim || 0}, end_time: ${data.end_trim || 0}, page: 0, page_size: 0){
        total
        items {
        id
        order_num
        team_id
        team_name
        operator_id
        operator_name
        price
        amount
        pay_type
        invoice_status
        invoiced_amount
        audit_status
        feishu_instance
        created_at
        remark
        details {
            package_id
            package_name
            price
            is_main
        }
        }
  }}`;
    try {
        const res = await axios.post(
            `/api/v1/graphql/backend`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                    "token": `${getToken()}`,
                },
            });
        if (res) {

            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}

export async function getorderbenefit(data: any): Promise<any> {
    const query = `
    { benefit_orders(start_at:${data.state_trim || 0}, end_at:${data.end_trim || 0}, field:"${data.title}", page:0, page_size:0, package_name: "${data.name || ""}", state:${data.kpstatue || 0}){
    team_id
    team_name
    company
    sales_agent_id
    sales_agent_name
    sales_agent_department
    created_at
    amount
    benefits{
      package_name
      benefit_type
      total
      surplus
      expires_at
    }
  }
  }`;
    try {
        const res = await axios.post(
            `/api/v1/graphql/backend`,
            ` ${query}`
            ,
            {
                headers: {
                    "Content-type": "application/graphql",
                    "token": `${getToken()}`,
                },
            });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}






export async function order_addinvoice(params: any): Promise<any> {
    try {
        const res = await axios.post(`/api/v1/admin/order/invoice`, params, {
            headers: {
                Token: localStorage.getItem("Token") || "",
            }
        });
        if (res) {
            return res;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}



interface getidQyListprops {
    ids: Array<any>
}
function convertParamsToQueryString(params: any) {
    const queryParts = [];

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];

            // 跳过未定义的键值
            if (value === undefined || value === null) {
                continue;
            }

            // 如果值是数组，逐个添加
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item) => {
                    if (item !== undefined && item !== null && item !== "") {
                        queryParts.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
                        );
                    }
                });
            }
            // 如果值是字符串且包含逗号，则拆分并逐个添加
            else if (typeof value === "string" && value.includes(",")) {
                value.split(",").forEach((item) => {
                    const trimmedItem = item.trim();
                    if (trimmedItem !== "") {
                        queryParts.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(trimmedItem)}`,
                        );
                    }
                });
            }
            // 如果值是非空的字符串或其他类型，直接添加
            else if (value !== "") {
                queryParts.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
                );
            }
            // 如果值是空字符串，添加键但不添加值
            else {
                queryParts.push(`${encodeURIComponent(key)}=`);
            }
        }
    }

    return queryParts.join("&");
}



export async function getidQyList(params: any): Promise<any> {
    try {
        const res = await axios({method: 'get', url: `/api/v1/admin/order/name?${convertParamsToQueryString(params)}`, headers: {
            Token: localStorage.getItem("Token") || "",
        }});
        if (res) {
            return res.data;
        } else {
            console.log("接口错误");
        }
    } catch (err) {
        return err;
    }
}










