import { makeAutoObservable } from "mobx";
import { Acr_Login } from "../../server/Login";
import { createorder, getidQYinfo, getidQyList, getmerberlist, getrolemerbeopdo, getroloedata, getteamdata, getteamorder, setadmin_userper, updatasetpost } from "../../server/role";
import { Message } from "@arco-design/web-react"

class Role_to {
    xsdatadepe = <any>[

    ]
    shdatadepe = <any>[

    ]
    depeact = [

    ]
    merberdata = [

    ]
    roleSelect_o = {
        size: 20,
        page: 1,
    }
    roledataselect = {
        name: '',
        title: '',
        guanlian: '',
        status: '',
        laiyuan: '',
        depeid: "",
        statetrim: "",
        endtrim: ""
    }
    merberdatatotal = null;
    detialroleid = null;

    roledetils = {}
    teamdetils = []
    QYdata = []
    QYdatadetil = []
    htimg = <any>[

    ]
    fkimg = [

    ]



    constructor() {
        makeAutoObservable(this);
    }


    setroleSelect_o(page: number) {
        if (page == 0) return
        this.roleSelect_o.page = page
        this.savadata();
    }

    
    setdataroleSelect_o(data: any) {
        this.roledataselect = data
        this.savadata();
    }
    savadata() {
        this.getmerberlist_to({
            ...this.roleSelect_o, ...this.roledataselect
        })
    }

    savadatadepe(data: any) {
        console.log(1, data);
        const depeact = <any>[]
        const sx = <any>[], sh = <any>[];
        if (data?.item && data.item?.length > 0) {
            data.item.map((i: any, index: number) => {
                if (!depeact.find((opl: any) => opl == i?.department_path)) {
                    if (!i.department_path) return
                    depeact.push({
                        title: i.department_path,
                        status: index
                    })
                }
                if (i?.department_path && i?.department_path.includes('营销')) {
                    sx.push(i)
                } else if (i?.department_path && i?.department_path.includes('售后')) {
                    sh.push(i)
                }
            })
        }
        this.depeact = depeact
        this.xsdatadepe = sx;
        this.shdatadepe = sh;
    }

    savamerberlsit() {
        const data = <any>[]
        if (!this.merberdata) return
        this.merberdata.map((i: any, index) => {
            data.push({
                id: i.uuid,
                onid: i.id,
                name: i.nickname,
                pla_home: i.phone_number,
                teamsum: i.team_count || 0,
                department_path: i.department_path,
                sales_agent_name: i.sales_agent_name,
                support_agent_name: i.support_agent_name,
                source: i.source,
                status: i.status,
                created_at: i.created_at,
            });
        })
        this.merberdata = data
    }


    // 获取全量部门角色数据
    getroloedata_to(props?: any) {
        try {
            getroloedata().then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (res?.data) {
                        this.savadatadepe(res.data)
                    }
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    // 获取用户列表i
    getmerberlist_to(props?: any) {
        try {
            getmerberlist(props).then((res) => {
                // if (res?.status == 200 && res?.statusText == "OK") {
                if (res?.data) {
                    // this.savadatadepe(res.data)
                    this.merberdata = res?.data?.data?.user_lists?.item
                    this.merberdatatotal = res.data?.data?.user_lists?.total
                    this.savamerberlsit();
                }
                else {
                    Message.info(res);
                }
                // } 

            });
        } catch (error) {
            console.error(error);
        }
    }

    // 获取用户详情 
    async getrolemerbeopdo_to(props?: any) {
        try {
            await getrolemerbeopdo(props).then((res) => {
                if (res?.data) {
                    // this.savadatadepe(res.data)
                    // this.merberdata = res?.data?.data?.user_lists?.item
                    // this.merberdatatotal = res.data?.data?.user_lists?.total
                    // this.savamerberlsit();
                    this.roledetils = res.data.data.user_detail.user_item
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    
    // 修改用户信息
    changeRoledetils(props) {
        this.roledetils = {
            ...this.roledetils,
            ...props,
        }
    }

    // 获取团队列表
    async getteamdata_to(props?: any) {
        try {
            await getteamdata(props).then((res) => {
                if (res?.data) {
                    // this.savadatadepe(res.data)
                    // this.merberdata = res?.data?.data?.user_lists?.item
                    // this.merberdatatotal = res.data?.data?.user_lists?.total
                    // this.savamerberlsit();
                    this.teamdetils = res.data.data.user_teams
                    // this.roledetils = res.data.data.user_detail.item
                } else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    // 通过权益类型id查询有哪些套餐名称
    async getidQyList_to(props: any) {
        try {
            return await getidQyList(props).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    // this.QYdata = res.data.items
                    return res.data.items
                }
                else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    // 通过权益类型id查询有哪些套餐名称
    async getidQYinfo_to(props: any) {
        try {
            return await getidQYinfo(props).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    return res.data.items
                    // this.QYdata = res.data.items
                    // this.QYdatadetil = res.data.items
                }
                else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    // 上传文件 合同
    async updatasetpost_toal(props: any) {
        try {
            return await updatasetpost(props).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (res.data?.url) {
                        return res.data
                        // this.htimg.push(res.data)
                        // console.log(res.data);
                        // console.log(this.htimg);
                    }

                }
                else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    // 上传文件
    async updatasetpost_to(props: any) {
        try {
            await updatasetpost(props).then((res) => {
                if (res?.code == 0 && res?.message == "OK") {
                    if (res.data?.url) {

                    }

                }
                else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    // 创建订单 提交订单
    async createorder_to(props: any) {
        try {
            await createorder(props).then((res) => {
                console.log(res);

                if (res?.code == 0 && res?.message == "OK") {
                    Message.success('创建订单成功')
                }
                else {
                    Message.info(res?.message);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    // 分配销售 
    async setadmin_userper_to(props: any) {
        try {
            await setadmin_userper(props).then((res) => {
                console.log(res, res.message);

                if (res?.data.code == 0 && res?.data.message == "OK") {
                    this.savadata();
                }
                else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    //通过id查找套餐名称 
    async getteamorder_to(props: any) {
        try {
            await getteamorder(props).then((res) => {
                console.log(res, res.message);

                if (res?.data.code == 0 && res?.data.message == "OK") {
                    this.savadata();
                }
                else {
                    Message.info(res);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
}
const rolestore = new Role_to();
export default rolestore;
