import Login from "./Login";
import rolestore from "./Role";
import Orderstore from "./Order";
import penCanterStoreOP from "./PersonalCenter/index"
import Config from "./Config"
export default {
    Login,
    rolestore,
    Orderstore,
    penCanterStoreOP,
    Config
}